<template>
  <div class="default-layout">
    <div class="header-wrapper">
      <TheHeader />
    </div>
    <main class="main">
      <slot />
    </main>
    <div class="footer-wrapper">
      <TheFooter />
    </div>

    <CallbackPopupPopup v-if="isCallBackPopupOpen" />
    <CountdownPopup v-if="isCountdownPopupOpen" />
    <VideoplayerPopup v-if="isVideoPlayerPopupOpen" />
    <ButtonToTop />
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import TheHeader from '~/components/common/TheHeader.vue';
import TheFooter from '~/components/common/TheFooter.vue';
import ButtonToTop from '~/components/wigets/ButtonToTop.vue';
import { useCallBackPopup } from '~/composables/useCallBackPopup';
import { useCountdownPopup } from '~/composables/useCountdownPopup';
import { useVideoplayerPopup } from '~/composables/useVideoplayerPopup';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';

const CallbackPopupPopup = defineAsyncComponent(() => import('~/components/popups/CallbackPopup.vue'));
const CountdownPopup = defineAsyncComponent(() => import('~/components/popups/CountdownPopup.vue'));
const VideoplayerPopup = defineAsyncComponent(() => import('~/components/popups/VideoplayerPopup.vue'));

const { isCallBackPopupOpen } = useCallBackPopup();
const { isCountdownPopupOpen, isCountdownWasShown, openCountdownPopup } = useCountdownPopup();
const { isVideoPlayerPopupOpen } = useVideoplayerPopup();
const appConfigStore = useAppConfigStore();
const { vars } = storeToRefs(appConfigStore);

const startVisibleCountdownTimerPopupBlock = () => {
  const seconds = vars?.value?.['Таймер появления обратного отсчета (в секундах)'];

  if (!seconds) {
    return;
  }

  setTimeout(() => {
    if (!isCountdownWasShown.value) {
      openCountdownPopup();
    }
  }, (Number.parseFloat(seconds) * 1000));
};

onMounted(() => {
  startVisibleCountdownTimerPopupBlock();
});
</script>
